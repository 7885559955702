export const structuredData = {
  home1: {
    "@context": "https://www.schema.org",
    "@type": "WebSite",
    name: "Cubet",
    alternateName: "Cubet",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    description:
      "Cubet: Your Digital Transformation Partner. We offer Custom Software Development + AI solutions for businesses, ensuring exceptional experiences on mobile, cloud, and web platforms.",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://cubettech.com/",
    },
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/@cubet_",
    ],
    potentialAction: [
      {
        "@type": "SearchAction",
        target:
          "https://cubettech.com/resources/searchResults/?keyword={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    ],
  },
  home2: {
    "@context": "https://www.schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
    foundingDate: "2007",
    founder: {
      "@type": "Person",
      name: "Lovegin John",
      sameAs: ["https://www.linkedin.com/in/lovegin/"],
    },

    address: [
      {
        "@type": "PostalAddress",
        streetAddress: "6595 Roswell Road, Suite G2007 Atlanta",
        addressLocality: "Atlanta",
        addressRegion: "Georgia",
        postalCode: "30328",
        addressCountry: "United States",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "Unit IX-C, 9th floor, Phase IV",
        addressLocality: "Kochi",
        addressRegion: "Kerala",
        postalCode: "682030",
        addressCountry: "India",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "71-75 Shelton Street, Covent Garden",
        addressLocality: "London",
        addressRegion: "England",
        postalCode: "WC2H 9JQ",
        addressCountry: "United Kingdom",
      },
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        email: "hello@cubettech.com",
        contactType: "customer service",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
  blog1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/resources/blog/`,
    name: "Blog | Our perspective on technology | Cubet",
    description:
      "Explore our blog for insightful articles, industry trends, and updates. Our blog covers a wide range of topics including technology, software development, digital transformation, and more. Stay up-to-date with the latest insights from Cubet!",
  },
  blog2: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
  career1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/cubet-career/`,
    name: "Careers | We are hiring | Discover oppurtunities | Cubet",
    description:
      "Explore exciting career opportunities at Cubet. We are constantly looking for talented individuals to join our team. Check out our current job openings and apply today to become part of our innovative and dynamic workforce!",
  },
  career2: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/cubet-career/`,
    logo: "https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
  l1Service1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Application Development Services | Cubet",
    url: "https://cubettech.com/services/application-development/",
    description:
      "Cubet provides enterprise-grade application development services, delivering AI-powered, cloud-native, and scalable solutions tailored for businesses worldwide.",
    image:
      "https://cubettech.com/assets/banner/application-development-banner.jpg",
    mainEntity: {
      "@type": "Service",
      name: "Application Development",
      serviceType: "Enterprise Application Development",
      provider: {
        "@type": "Organization",
        name: "Cubet",
        url: "https://cubettech.com/",
        logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
        contactPoint: {
          "@type": "ContactPoint",
          email: "hello@cubettech.com",
          contactType: "customer service",
          availableLanguage: ["en", "es"],
        },
        sameAs: [
          "https://www.facebook.com/CubetTech/",
          "https://twitter.com/CubetTech",
          "https://www.instagram.com/cubettechnolabs/",
          "https://www.linkedin.com/company/cubet-technologies",
          "https://www.youtube.com/@cubet_",
        ],
      },
      audience: {
        "@type": "BusinessAudience",
        name: "Micro to Large Enterprises",
        numberOfEmployees: {
          "@type": "QuantitativeValue",
          minValue: 1,
          maxValue: 10000,
        },
        geographicArea: "Worldwide",
      },
      areaServed: ["Worldwide"],
      offers: {
        "@type": "Offer",
        price: "25.00",
        priceCurrency: "USD",
        availability: "https://schema.org/InStock",
        url: "https://cubettech.com/contact/",
      },
    },
  },
  l1Service2: {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://cubettech.com/",
          name: "Home",
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://cubettech.com/services/application-development/",
          name: "Application Development",
        },
      },
    ],
  },
  l1Service3: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Cubet",
    url: "https://cubettech.com/",
    logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
    description:
      "Cubet: Your Digital Transformation Partner. We offer Custom Software Development + AI solutions for businesses, ensuring exceptional experiences on mobile, cloud, and web platforms.",
    foundingDate: "2007",
    founder: {
      "@type": "Person",
      name: "Lovegin John",
      sameAs: "https://www.linkedin.com/in/lovegin/",
    },
    address: [
      {
        "@type": "PostalAddress",
        streetAddress: "6595 Roswell Road, Suite G2007",
        addressLocality: "Atlanta",
        addressRegion: "Georgia",
        postalCode: "30328",
        addressCountry: "US",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "Unit IX-C, 9th Floor, Phase IV",
        addressLocality: "Kochi",
        addressRegion: "Kerala",
        postalCode: "682030",
        addressCountry: "IN",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "71-75 Shelton Street, Covent Garden",
        addressLocality: "London",
        addressRegion: "England",
        postalCode: "WC2H 9JQ",
        addressCountry: "UK",
      },
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        email: "hello@cubettech.com",
        contactType: "customer service",
        availableLanguage: ["en", "es"],
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/@cubet_",
    ],
  },

  l2Service1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Web App Development Services | Cubet",
    alternateName: "Cubet",
    description:
      "Cubet offers custom web app development solutions for businesses, leveraging AI, cloud, and scalable technology stacks to create enterprise-grade applications.",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/services/web-app-development/`,
    image:
      "https://cubettech.com/assets/banner/application-development-banner.jpg",
    mainEntity: {
      "@type": "Service",
      name: "Web Application Development",
      serviceType: "Custom Web App Development for Businesses",
      provider: {
        "@type": "Organization",
        name: "Cubet",
        url: "https://cubettech.com/",
        logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
        contactPoint: {
          "@type": "ContactPoint",
          email: "hello@cubettech.com",
          contactType: "customer service",
          availableLanguage: ["en", "es"],
        },
        sameAs: [
          "https://www.facebook.com/CubetTech/",
          "https://twitter.com/CubetTech",
          "https://www.instagram.com/cubettechnolabs/",
          "https://www.linkedin.com/company/cubet-technologies",
          "https://www.youtube.com/@cubet_",
        ],
      },
      audience: {
        "@type": "BusinessAudience",
        name: "Micro to Large Enterprises",
        numberOfEmployees: {
          "@type": "QuantitativeValue",
          minValue: 50,
          maxValue: 10000,
        },
        geographicArea: "Worldwide",
      },
      areaServed: ["Worldwide"],
      offers: {
        "@type": "Offer",
        price: "25.00",
        priceCurrency: "USD",
        availability: "https://schema.org/InStock",
        url: "https://cubettech.com/contact/",
      },
    },
  },
  l2Service2: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Cubet",
    url: "https://cubettech.com/",
    logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
    description:
      "Cubet: Your Digital Transformation Partner. We offer Custom Software Development + AI solutions for businesses, ensuring exceptional experiences on mobile, cloud, and web platforms.",
    foundingDate: "2007",
    founder: {
      "@type": "Person",
      name: "Lovegin John",
      sameAs: "https://www.linkedin.com/in/lovegin/",
    },
    address: [
      {
        "@type": "PostalAddress",
        streetAddress: "6595 Roswell Road, Suite G2007",
        addressLocality: "Atlanta",
        addressRegion: "Georgia",
        postalCode: "30328",
        addressCountry: "US",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "Unit IX-C, 9th Floor, Phase IV",
        addressLocality: "Kochi",
        addressRegion: "Kerala",
        postalCode: "682030",
        addressCountry: "IN",
      },
      {
        "@type": "PostalAddress",
        streetAddress: "71-75 Shelton Street, Covent Garden",
        addressLocality: "London",
        addressRegion: "England",
        postalCode: "WC2H 9JQ",
        addressCountry: "UK",
      },
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        email: "hello@cubettech.com",
        contactType: "customer service",
        availableLanguage: ["en", "es"],
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/@cubet_",
    ],
  },
  l2Service3: {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "https://cubettech.com/",
          name: "Home",
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://cubettech.com/services/application-development/",
          name: "Application Development",
        },
      },
      {
        "@type": "ListItem",
        position: 3,
        item: {
          "@id": "https://cubettech.com/services/web-app-development/",
          name: "Web App Development",
        },
      },
    ],
  },
  about1: {
    "@context": "https://www.schema.org",
    "@type": "WebPage",
    name: "Cubet About Us",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/about-us/`,
    description:
      "Looking for IT consulting services? Cubet is a global leader in next generation solutions, services and consulting, with over decades of experience. Visit our page to learn more about our services!",
  },
  about2: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
  about3: {
    "@context": "https://www.schema.org",
    "@type": "WebPage",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/solutions/`,
    name: "Artificial Intelligence (AI), Machine Learning, IoT Services & Solutions | Cubet",
    description:
      "Cubet offers the top-notch Artificial Intelligence (AI), Machine Learning, the Internet of Things (IoT) Services & Solutions. We build solutions that utilize our technical expertise and experience for solving domain-specific business challenges. Contact us today to know more!!",
  },
  about4: {
    "@type": "AggregateRating",
    "@bestRating": "5",
    "@awards": "Laravel Partner Company",
  },
  industries1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/industries/`,
    name: "Industries We Serve | Cubet",
    description:
      "Cubet serves various industries by providing innovative software solutions. Our expertise extends to industries such as healthcare, finance, e-commerce, education, and more. Contact us today to discuss how we can help your industry thrive!",
  },
  industries2: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
  casestudy1: {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/resources/case-studies/`,
    name: "Case Studies | Success Stories | Cubet",
    description:
      "Explore our case studies and success stories to see how Cubet has helped businesses achieve their goals. Learn about our innovative solutions and the positive impact they have made across various industries. Contact us today to discuss your project!",
  },
  casestudy2: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cubet",
    alternateName: "Cubet Tech",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en",
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en",
      },
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech",
    ],
  },
};
